import { Cafe, TimerInfoState } from "../../../../api/types";
import { getDistanceKm } from "../../../../utils/common";
import { calculateTimes } from "../../../../utils/timer";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { geoCoordState } from "../../../../recoil/geo";
import { PremiumStoreInfoTopWrapper } from "../../Search/Style/PremiumStoreStyle";
import {
  PremiumStoreInfoWrapper,
  PremiumStoreInfoBottomWrapper,
  PremiumStoreTournamentInfoWrapper,
  PremiumStoreCircle,
} from "../style";
import { currentTimeState } from "../../../../recoil/app";
import { useHistory } from "react-router-dom";
import OptimizedImage from "../../../../components/aws/OptimizedImage";
interface PremiumStoreItemProps {
  item: Cafe;
  setSelectedStore: (value: Cafe) => void;
  setShowMoreOption: (value: boolean) => void;
}

export const FranchiseItem = ({
  item,
  setSelectedStore,
  setShowMoreOption,
}: PremiumStoreItemProps) => {
  const { latitude, longitude } = useRecoilValue(geoCoordState);
  const history = useHistory();
  const currentTime = useRecoilValue(currentTimeState);
  const [timer, setTimer] = useState<TimerInfoState | null>(null);
  const handleMoreOption = useCallback((cafe: Cafe, e: React.MouseEvent) => {
    setSelectedStore(cafe);
    setShowMoreOption(true);
    e.stopPropagation();
  }, []);

  const radius = 28.5;
  const circumference = 2 * Math.PI * radius;
  useEffect(() => {
    if (!item.pubTournamentList) return;
    if (item.pubTournamentList.length < 1) return;
    if (!item.pubTournamentList[0].timerDto) return;
    const tournament = item.pubTournamentList?.find(
      (pubTournament) => pubTournament.timerDto
    );

    const time = tournament
      ? calculateTimes(tournament.timerDto!, tournament.structure)
      : undefined;
    if (time && time.overStack > 1) {
      return;
    }

    setTimer(
      calculateTimes(
        item.pubTournamentList[0].timerDto!,
        item.pubTournamentList[0].structure
      )
    );
  }, [currentTime]);

  return (
    <PremiumStoreInfoWrapper
      onClick={() => {
        history.push(`/store/${item.id}?type=PREMIUM`);
      }}
    >
      <div className="thumbnail">
        <OptimizedImage
          alt="cover"
          className="cover"
          src={
            item?.images[0]
              ? item.images[0].imageUrl
              : "https://dfesoodpx4jgd.cloudfront.net/cafe/default.png"
          }
        />
      </div>
      <PremiumStoreInfoTopWrapper>
        <div className="info">
          <div className="title-row">
            <div className="name">{item.cafeName} </div>
            <img
              src="/image-web/search/Icon/more.svg"
              alt="more"
              onClick={(e) => handleMoreOption(item, e)}
            />
          </div>
          <div className="review-box">
            <img
              alt="premium mark"
              className="premium"
              src="/image-web/store/premium.png"
            />
            <div className="review-score">
              {item.score && (item.score === 0 ? 0 : item.score.toFixed(1))}
            </div>
            <div className="review-count">{`리뷰 ${item.reviewCount}`}</div>
          </div>
        </div>
      </PremiumStoreInfoTopWrapper>
      <PremiumStoreInfoBottomWrapper>
        {!timer && <div className="state">게임준비중</div>}
        {timer && <div className="state">현재진행중</div>}
        <div className="info">
          <div className="address">
            {item.newAddress} {item.detailAddress}
          </div>
          <div className="info-row">
            <div className="item">
              <img
                alt="icon"
                className="icon"
                src="/image-web/Icon/Heart/small-white.svg"
              />
              <span className="text">{item.likeCount}</span>
            </div>
            <div className="item">
              <img
                alt="icon"
                className="icon"
                src="/image-web/Icon/Map%20pin/small-white.svg"
              />
              <span className="text">
                {getDistanceKm(latitude, longitude, item.lat, item.lon)} km
              </span>
            </div>
          </div>
        </div>
      </PremiumStoreInfoBottomWrapper>
    </PremiumStoreInfoWrapper>
  );
};
