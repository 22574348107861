import styled from "styled-components";

export const AlarmWrapper = styled.div<{ isApp: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 12px 40px;
  margin-top: ${(p) => (p.isApp ? "44px" : "0px")};
`;

export const Header = styled.div<{ isApp: boolean }>`
  position: fixed;
  max-width: 500px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 20px;
  width: 100%;
  min-height: 48px;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  background: white;
  padding-top: ${(p) => (p.isApp ? "44px" : "0px")};
  padding-bottom: ${(p) => (p.isApp ? "13px" : "0px")};

  > .right {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .close {
      cursor: pointer;
      width: 24px;
      height: 24px;

      svg path {
        stroke: var(--Black-400, #444);
      }
    }

    .title {
      transition: all 0.1s ease-in-out;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .left {
    gap: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    > .alarm {
      position: relative;
      > .icon {
        top: 3px;
        right: 3px;
        position: absolute;
        width: 5px;
        height: 5px;
      }
    }

    img {
      width: 29px;
      height: 29px;
    }
  }
`;

export const AlarmListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 60px;

  > .no-data {
    text-align: center;
    font-family: Pretendard;
    font-weight: 500;
    font-size: 13px;
  }
`;

export const AlarmItemWrapper = styled.div`
  background: #f0f0f0;
  min-height: 50px;
  height: auto;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;

  > .icon {
    min-width: 35px;
    width: 35px;
    height: 35px;
    background: #6350fc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 23px;
      height: 23px;
    }
  }

  > .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > .top {
      display: flex;
      gap: 4px;

      > .title {
        font-family: Pretendard;
        font-weight: 500;
        font-size: 10px;
      }

      > .time {
        font-family: Pretendard;
        font-weight: 500;
        font-size: 10px;
        color: #a4a4a4;
      }
    }

    > .text {
      font-family: Pretendard;
      font-weight: 700;
      font-size: 13px;
    }
  }
`;
