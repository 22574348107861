import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../../../recoil/auth";
import { cafeDetail } from "../../../api/cafe";
import { Cafe, ReservationResponse } from "../../../api/types";
import useScreenOrientation from "../../../hooks/useScreenOrientation";
import { isApp } from "../../../hooks/useNativeApp";
import { enqueueSnackbar } from "notistack";
import {
  ButtonWrapper,
  Container,
  FloatButton,
  Label,
  PubContainer,
  ReservationButton,
  ReservationContainer,
  ReservationHeader,
  ReservationInfoButton,
  TournamentImage,
  TournamentImageWrapper,
  ValueLabel,
  WarningWrapper,
} from "./style";
import ImageViewerModal from "../../../components/common/ImageViewerModal";
import { shareURL } from "../../../utils/common";
import ReservationStatusPopup from "./component/ReservationStatusPopup";
import ReservationPopup from "./component/ReservationPopup";
import OpenApp from "./component/OpenApp";
import ReservationEnd from "./component/ReservationEndPopup";
import { Reservation } from "./types";
import {
  getCompanyCurrentReservations,
  getCompanyReservation,
} from "../../../api/reservation-company";

const CompanyReservation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user] = useRecoilState(userState);
  const history = useHistory();
  const [isReservationPopupOpen, setIsReservationPopupOpen] = useState(false);
  const [cafe, setCafe] = useState<Cafe | null>(null);
  const [reservation, setReservation] = useState<ReservationResponse | null>(
    null
  );
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [type, setType] = useState<string>("");
  const [isOpenUseApp, setIsOpenUseApp] = useState(false);
  const [isFinalReservation, setIsFinalReservation] = useState(false);
  const [totalReservations, setTotalReservations] = useState(0);
  const [reservationId, setReservationId] = useState<number>(0);
  const [keepSeed, setKeepSeed] = useState(0);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [isReservation, setIsReservation] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isThreeDaysBefore, setIsThreeDaysBefore] = useState<boolean>(false);
  const [dDay, setDDay] = useState<number>(0);

  const orientation = useScreenOrientation();
  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    if (!id) return;
    getCompanyReservation(id).then((reservation) => {
      setReservation(reservation);
      cafeDetail(reservation.cafeId).then(setCafe);
      setKeepSeed(reservation.keepSeed);
      setIsEnd(reservation.isEnd);
      if (
        reservation.limitEntry &&
        reservation.reservationCount === reservation.limitEntry
      ) {
        setIsEnd(true);
      }
      const startDate = new Date(reservation.tournament.startDate);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const startDateOnly = new Date(startDate);
      startDateOnly.setHours(0, 0, 0, 0);

      const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

      const threeDaysBefore = new Date(currentDate.getTime() - threeDaysInMs);
      if (
        startDateOnly.getTime() <= currentDate.getTime() &&
        startDateOnly.getTime() > threeDaysBefore.getTime()
      ) {
        setIsThreeDaysBefore(true);
        const dDayRemaining = Math.ceil(
          (startDateOnly.getTime() - currentDate.getTime()) /
            (1000 * 60 * 60 * 24)
        );
        setDDay(dDayRemaining);
      }
    });

    getCompanyCurrentReservations(id).then((response) => {
      setTotalReservations(response.length);
      setReservations(
        response.map((item, index) => {
          return {
            order: response.length - index + "",
            customerName: item.nickname,
            id: item.id,
          };
        })
      );
    });
  };

  useEffect(() => {
    if (!user) return;

    const isReservation = reservations.some(
      (item) => item.customerName === user.nickname
    );
    setIsReservation(isReservation);
    if (isReservation) {
      const reservation = reservations.filter(
        (item) => item.customerName === user.nickname
      );
      setReservationId(reservation[0].id);
    }
  }, [reservations, user]);

  useEffect(() => {
    if (!isApp && orientation !== "landscape") {
      setIsOpenUseApp(true);
    }
  }, []);

  const handleClose = () => {
    if (window.history.length > 1) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  const goToApp = () => {
    if (!isApp) {
      const isKakao = /KAKAOTALK/i.test(navigator.userAgent);
      const redirectUrl =
        window.location.href +
        (window.location.search ? "&" : "?") +
        "store_redirect=true";
      // 앱 실행 시도
      if (isKakao) {
        window.location.href =
          "kakaotalk://web/openExternal?url=" + encodeURIComponent(redirectUrl);
      } else {
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (isIOS) {
          const link = document.createElement("a");
          link.href = redirectUrl;
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(redirectUrl, "_blank");
          window.close();
        }
      }
      return;
    }
  };

  useEffect(() => {
    // URL에서 store_redirect 파라미터 확인
    const params = new URLSearchParams(window.location.search);
    const shouldRedirect = params.get("store_redirect");

    if (shouldRedirect === "true" && !isApp) {
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      if (isIOS) {
        window.location.href = "https://apps.apple.com/app/6469495456";
      } else {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.runnersoft.runnerrunner";
      }
    }
  }, []);

  const handleReservationClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!user) {
      enqueueSnackbar("로그인이 필요합니다. ", { variant: "error" });
      history.push("/login");
      return;
    }
    if (isReservation) {
      if (isThreeDaysBefore) {
        return;
      }
      setType("cancel");
    } else {
      if (isEnd) {
        enqueueSnackbar("예약이 마감되었습니다. ", { variant: "error" });
        return;
      }
      setType("reservation");
    }
    setIsReservationPopupOpen(true);
  };
  return (
    <Container>
      {reservation && (
        <ReservationContainer>
          <ReservationHeader>
            <div className="close" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 19L5 12M5 12L12 5M5 12H19"
                  strokeWidth="1.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="title">
              {cafe?.cafeName ? cafe.cafeName : "예약"}
            </div>
          </ReservationHeader>

          <div className="title">{reservation.tournament.title}</div>
          {cafe && (
            <PubContainer>
              <div className="row">
                <Label>매장이름</Label>
                <ValueLabel>{cafe.cafeName}</ValueLabel>
              </div>
              <div className="row">
                <Label>주소</Label>
                <ValueLabel>{cafe.newAddress}</ValueLabel>
              </div>
              <div className="row">
                <Label>매장번호</Label>
                <ValueLabel>{cafe.vcn}</ValueLabel>
              </div>
            </PubContainer>
          )}
          <WarningWrapper>
            ※불가피하게 예약 취소를 원하시는 경우 매장 으로 전화 부탁 드립니다.
          </WarningWrapper>
          <ButtonWrapper>
            <ReservationInfoButton onClick={() => setIsStatusPopupOpen(true)}>
              예약현황({totalReservations}명)
            </ReservationInfoButton>
            <ReservationButton
              onClick={(e) => {
                handleReservationClick(e);
              }}
            >
              {isReservation
                ? isThreeDaysBefore
                  ? dDay === 0
                    ? "D-Day"
                    : "D-" + dDay
                  : "예약 취소"
                : isEnd
                ? "예약 마감"
                : "토너먼트 예약하기"}
            </ReservationButton>
          </ButtonWrapper>
          <TournamentImageWrapper>
            <TournamentImage
              alt="토너먼트"
              src={reservation.imageUrl}
              onClick={() => setSelectedImage(reservation.imageUrl)}
            ></TournamentImage>
            <FloatButton
              onClick={() =>
                shareURL(window.location.href, reservation.tournament.title)
              }
            >
              <img src="/image-web/reservation/share_button.svg" alt="To Map" />
            </FloatButton>
          </TournamentImageWrapper>
        </ReservationContainer>
      )}

      <ReservationStatusPopup
        isOpen={isStatusPopupOpen}
        onClose={() => setIsStatusPopupOpen(false)}
        id={id}
        totalReservations={totalReservations}
        setTotalReservations={setTotalReservations}
        reservations={reservations}
      />
      {isReservationPopupOpen && (
        <ReservationPopup
          onClose={() => setIsReservationPopupOpen(false)}
          reservationId={id || ""}
          setIsFinalReservation={setIsFinalReservation}
          keepSeed={keepSeed}
          type={type}
          tournamentName={reservation ? reservation.tournament.title : ""}
          id={reservationId}
        />
      )}
      <ImageViewerModal
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        imageUrl={selectedImage || ""}
      ></ImageViewerModal>
      {isOpenUseApp && (
        <OpenApp
          openApp={() => goToApp()}
          onClose={() => setIsOpenUseApp(false)}
        />
      )}
      {isFinalReservation && (
        <ReservationEnd
          onClose={() => {
            setIsFinalReservation(false);
            getData();
          }}
          tournamentTitle={reservation ? reservation.tournament.title : ""}
          keepSeed={keepSeed}
          type={type}
        />
      )}
    </Container>
  );
};

export default CompanyReservation;
