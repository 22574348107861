import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../../../recoil/auth";
import {
  getReservation,
  registerReservation,
  TournamentReservationParams,
} from "../../../api/reservation";
import { cafeDetail } from "../../../api/cafe";
import { Cafe, ReservationResponse } from "../../../api/types";
import {
  Container,
  TournamentImageWrapper,
  PubContainer,
  ReservationContainer,
  ValueLabel,
  ReservationButton,
  ButtonWrapper,
  ReservationInfoButton,
  Label,
  WarningWrapper,
  FloatButton,
  TournamentImage,
  ReservationHeader,
} from "./index.styles";
import ReservationStatusPopup from "./ReservationStatusPopup";
import ReservationPopup from "./ReservationPopup";
import { shareURL } from "../../../utils/common";
import Header from "../StoreDetail/Header";
import { isApp } from "../../../hooks/useNativeApp";
import ImageViewerModal from "../../../components/common/ImageViewerModal";
import { enqueueSnackbar } from "notistack";
import OpenApp from "./OpenApp";
import useScreenOrientation from "../../../hooks/useScreenOrientation";

const ReservationLandingPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user] = useRecoilState(userState);
  const history = useHistory();
  const [isReservationPopupOpen, setIsReservationPopupOpen] = useState(false);
  const [cafe, setCafe] = useState<Cafe | null>(null);
  const [reservation, setReservation] = useState<ReservationResponse | null>(
    null
  );
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isOpenUseApp, setIsOpenUseApp] = useState(false);
  const orientation = useScreenOrientation();
  const [totalReservations, setTotalReservations] = useState(0);
  useEffect(() => {
    if (id) {
      getReservation(id).then((reservation) => {
        setReservation(reservation);
        cafeDetail(reservation.cafeId).then(setCafe);
      });
    }
  }, [id]);

  useEffect(() => {
    if (!isApp && orientation !== "landscape") {
      setIsOpenUseApp(true);
    }
  }, []);

  const handleClose = () => {
    if (window.history.length > 1) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  const createReservation = async () => {
    if (!user) return;
    const body: TournamentReservationParams = {
      reservationId: id,
      nickName: user.nickname,
      phoneNumber: "",
      userId: user.id,
      root: isApp ? "APP" : "WEB",
    };
    try {
      const response = await registerReservation(body);
      enqueueSnackbar(response.message);
    } catch (error: any) {
      enqueueSnackbar(error.message);
    }
  };

  const goToApp = () => {
    if (!isApp) {
      const isKakao = /KAKAOTALK/i.test(navigator.userAgent);
      const redirectUrl =
        window.location.href +
        (window.location.search ? "&" : "?") +
        "store_redirect=true";
      // 앱 실행 시도
      if (isKakao) {
        window.location.href =
          "kakaotalk://web/openExternal?url=" + encodeURIComponent(redirectUrl);
      } else {
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (isIOS) {
          const link = document.createElement("a");
          link.href = redirectUrl;
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(redirectUrl, "_blank");
          window.close();
        }
      }
      return;
    }
  };

  useEffect(() => {
    // URL에서 store_redirect 파라미터 확인
    const params = new URLSearchParams(window.location.search);
    const shouldRedirect = params.get("store_redirect");

    if (shouldRedirect === "true" && !isApp) {
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      if (isIOS) {
        window.location.href = "https://apps.apple.com/app/6469495456";
      } else {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.runnersoft.runnerrunner";
      }
    }
  }, []);

  const handleReservationClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!user) {
      setIsReservationPopupOpen(true);
      return;
    }
    createReservation();
  };
  return (
    <Container>
      {reservation && (
        <ReservationContainer isApp={false}>
          <ReservationHeader isApp={false}>
            <div className="close" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 19L5 12M5 12L12 5M5 12H19"
                  strokeWidth="1.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="title">
              {cafe?.cafeName ? cafe.cafeName : "예약"}
            </div>
          </ReservationHeader>

          <div className="title">{reservation.tournament.title}</div>
          {cafe && (
            <PubContainer>
              <div className="row">
                <Label>매장이름</Label>
                <ValueLabel>{cafe.cafeName}</ValueLabel>
              </div>
              <div className="row">
                <Label>주소</Label>
                <ValueLabel>{cafe.newAddress}</ValueLabel>
              </div>
              <div className="row">
                <Label>매장번호</Label>
                <ValueLabel>{cafe.vcn}</ValueLabel>
              </div>
            </PubContainer>
          )}
          <WarningWrapper>
            ※불가피하게 예약 취소를 원하시는 경우 매장 으로 전화 부탁 드립니다.
          </WarningWrapper>
          <ButtonWrapper>
            <ReservationInfoButton onClick={() => setIsStatusPopupOpen(true)}>
              예약현황({totalReservations}명)
            </ReservationInfoButton>
            <ReservationButton
              onClick={(e) => {
                handleReservationClick(e);
              }}
            >
              토너먼트 예약하기
            </ReservationButton>
          </ButtonWrapper>
          <TournamentImageWrapper>
            <TournamentImage
              alt="토너먼트"
              src={reservation.imageUrl}
              onClick={() => setSelectedImage(reservation.imageUrl)}
            ></TournamentImage>
            <FloatButton
              onClick={() =>
                shareURL(window.location.href, reservation.tournament.title)
              }
            >
              <img src="/image-web/reservation/share_button.svg" alt="To Map" />
            </FloatButton>
          </TournamentImageWrapper>
        </ReservationContainer>
      )}

      <ReservationStatusPopup
        isOpen={isStatusPopupOpen}
        onClose={() => setIsStatusPopupOpen(false)}
        id={id}
        totalReservations={totalReservations}
        setTotalReservations={setTotalReservations}
      />
      {isReservationPopupOpen && (
        <ReservationPopup
          isOpen={isReservationPopupOpen}
          onClose={() => setIsReservationPopupOpen(false)}
          reservationId={id || ""}
        />
      )}
      <ImageViewerModal
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        imageUrl={selectedImage || ""}
      ></ImageViewerModal>
      {isOpenUseApp && (
        <OpenApp
          openApp={() => goToApp()}
          onClose={() => setIsOpenUseApp(false)}
        />
      )}
    </Container>
  );
};

export default ReservationLandingPage;
