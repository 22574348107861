import React, { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { isApp } from "../../../../hooks/useNativeApp";
import {
  ButtonWrapper,
  ContentWrapper,
  DialogWrapper,
  FormContainer,
} from "../style/PopupStyles";
import { useRecoilState } from "recoil";
import { userState } from "../../../../recoil/auth";
import {
  registerCompanyReservation,
  CompanyReservationParams,
  cancelCompanyReservation,
} from "../../../../api/reservation-company";

interface ReservationPopupProps {
  onClose: () => void;
  reservationId: string;
  setIsFinalReservation: (value: boolean) => void;
  keepSeed: number;
  type: string;
  tournamentName: string;
  id: number;
}

const ReservationPopup: React.FC<ReservationPopupProps> = ({
  onClose,
  reservationId,
  setIsFinalReservation,
  keepSeed,
  type,
  tournamentName,
  id,
}) => {
  const [user] = useRecoilState(userState);

  const createReservation = async () => {
    if (!user) return;
    const body: CompanyReservationParams = {
      reservationId: reservationId,
      userId: user.id,
      root: isApp ? "APP" : "WEB",
    };
    try {
      await registerCompanyReservation(body);
      setIsFinalReservation(true);
      onClose();
    } catch (error: any) {
      onClose();
      enqueueSnackbar(error.message);
    }
  };

  const cancelReservation = async () => {
    if (!user) return;

    try {
      await cancelCompanyReservation(id);
      setIsFinalReservation(true);
      onClose();
    } catch (error: any) {
      onClose();
      enqueueSnackbar(error.message);
    }
  };

  return (
    <DialogWrapper>
      <FormContainer>
        <ContentWrapper>
          {type === "reservation" ? (
            <>
              <div className="seed">
                `{user?.nickname}`님이 보유한 통합시드
                <br />
                {user?.seed ? user?.seed.toLocaleString() : 0} RABBIT
              </div>
              <div className="content">토너먼트를 예약 하시겠습니까?</div>
              <div className="waring">
                *통합시드 {keepSeed}RABBIT 만큼 차감됩니다.
                <br />
                *00시 기준 대회 시작 3일 전에는 예약 취소가 불가능합니다.
              </div>
            </>
          ) : (
            <>
              <div className="content">
                {tournamentName}
                <br />
                예약을 취소하시겠습니까?
              </div>
            </>
          )}
        </ContentWrapper>
        <ButtonWrapper>
          <div
            className="button purple"
            onClick={() => {
              if (type === "reservation") {
                createReservation();
              } else {
                cancelReservation();
              }
            }}
          >
            예
          </div>
          <div className="button" onClick={() => onClose()}>
            아니오
          </div>
        </ButtonWrapper>
      </FormContainer>
    </DialogWrapper>
  );
};

export default ReservationPopup;
