import React, { useEffect } from "react";
import { GameLayout } from "./GameLayout";
import { GameNavigator } from "./GameNavigator";
import { GameMain } from "./Main/GameMain";
import { GameHeader } from "./GameHeader";
import { useGameContext } from "./Hook/GameContext";
import { GamePage } from "./Hook/useGame";
import { GameLobby } from "./Lobby/GameLobby";
import { GameMyPage } from "./MyPage/GameMyPage";
import { GameRank } from "./Rank/GameRank";
import GameDialog from "./Component/Dialog";
import RankingDialog from "./Component/RankingDialog";
import { GameInfoDialog } from "./Component/GameInfoDialog";
import { AttendanceDialog } from "./Component/attendanceDialog";
import GameCouponDialog from "./Component/CouponDialog";

const GameContent: React.FC = () => {
  const {
    history,
    gamePageState,
    dialogState,
    isRankingDialogOpen,
    gameInfoData,
    dailyAttendanceCheck,
    attendanceList,
    setAttendanceList,
    couponDialogState,
    checkIsValidate,
    openDialog,
  } = useGameContext();
  const attendanceCheck = async () => {
    const data = await dailyAttendanceCheck();
    if (data) setAttendanceList(data);
  };
  useEffect(() => {
    checkIsValidate();
    attendanceCheck();
  }, []);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      // 뒤로가기 버튼이 눌렸을 때
      event.preventDefault();
      openDialog({
        text: `게임을\n그만하시겠습니까?`,
        buttonText: "퇴장하기",
        onConfirm: () => {
          history.replace("/");
        },
      });
      window.history.pushState(null, "", window.location.pathname);
    };

    window.history.pushState(null, "", window.location.pathname);

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);
  return (
    <>
      <GameLayout>
        {gamePageState === GamePage.MAIN && <GameMain></GameMain>}
        {gamePageState === GamePage.RANKING && <GameRank></GameRank>}
        {gamePageState === GamePage.MY_PAGE && <GameMyPage></GameMyPage>}
        {gamePageState === GamePage.GAME && <GameLobby></GameLobby>}
        <GameHeader></GameHeader>
        <GameNavigator></GameNavigator>
        {couponDialogState && <GameCouponDialog data={couponDialogState} />}
        {dialogState && <GameDialog data={dialogState} />}
        {isRankingDialogOpen && <RankingDialog />}
        {gameInfoData && <GameInfoDialog />}
        {attendanceList.length > 0 && <AttendanceDialog />}
      </GameLayout>
    </>
  );
};

export default GameContent;
