export const isEdited = (data: any) => {
  return data.editAt ? true : false;
};
export const isEditedComment = (data: any) => {
  return data.updatedAt > data.createdAt ? true : false;
};

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}시${minutes}분`;
}
