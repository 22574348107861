import React, { useEffect, useState } from "react";
import { Reservation } from "../types";
import { Title } from "../style/PopupStyles";
import {
  ModalOverlay,
  ModalContent,
  Table,
  TableHeader,
  TableRow,
  TableCell,
} from "../style/ReservationStatusPopup";
import Pagination from "../../../../components/web/Pagination";

const ITEMS_PER_PAGE = 10;

export interface ReservationStatusPopupProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  totalReservations: number;
  setTotalReservations: (value: number) => void;
  reservations: Reservation[];
}

const ReservationStatusPopup = ({
  id,
  isOpen,
  onClose,
  totalReservations,
  reservations,
}: ReservationStatusPopupProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageList, setPageList] = useState<Reservation[]>([]);

  useEffect(() => {
    setTotalPages(Math.floor(reservations.length / ITEMS_PER_PAGE) + 1);
    setPageList(
      reservations.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
      )
    );
  }, [reservations, currentPage]);
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <Title>예약 현황({totalReservations}명)</Title>
          <div onClick={onClose}>&times;</div>
        </div>
        <Table>
          <thead>
            <tr>
              <TableHeader>NO.</TableHeader>
              <TableHeader>닉네임</TableHeader>
            </tr>
          </thead>
          <tbody>
            {pageList.map((reservation: Reservation, index: number) => (
              <TableRow key={reservation.id}>
                <TableCell>
                  {reservations.length -
                    reservations.findIndex((item) => item === pageList[index])}
                </TableCell>
                <TableCell>{reservation.customerName}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          size={22}
        ></Pagination>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ReservationStatusPopup;
