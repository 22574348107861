import styled from "styled-components";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { navigatorShadowState } from "../../recoil/navigator";
import React from "react";
import Alarm from "./Alarm";
const Home = React.lazy(() => import("./NewHome"));
const Search = React.lazy(() => import("./NewSearch"));
const Index = React.lazy(() => import("./MyPage"));
const Favorite = React.lazy(() => import("./Favorite"));
const UseCafe = React.lazy(() => import("./Wallet"));
const SeedDetail = React.lazy(() => import("./SeedDetail"));
const Rank = React.lazy(() => import("./Rank"));

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 80px;
  -webkit-overflow-scrolling: touch;
`;

const BottomNavigationWrapper = styled.div<{ $isShadow?: boolean }>`
  position: fixed;
  width: 100%;
  max-width: 500px;
  background: white;
  bottom: 0;
  padding: 9px 16px 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ $isShadow = true }) =>
    $isShadow &&
    `
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px 0px;
  `}
  z-index: 120;

  > .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    > .icon-wrapper {
      width: 29px;
      height: 29px;

      > .icon {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }

    > .name {
      color: #676769;
      font-family: Pretendard;
      font-weight: 500;
      font-size: 11px;
      line-height: 100%;
      letter-spacing: -0.27px;
      text-align: center;
      vertical-align: middle;
    }
  }

  > .item.selected {
    > .name {
      color: #6436e7;
    }
  }
`;

const Slider = styled.div<{ left: number; width: number }>`
  position: absolute;
  bottom: 28px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  height: 38px;
  background-color: #6436e7;
  border-radius: 999px;
  z-index: -1;
`;

type MenuType =
  | "home"
  | "like"
  | "search"
  | "mypage"
  | "wallet"
  | "name"
  | "rank"
  | "alarm"
  | "seed";
const Main = () => {
  const history = useHistory();
  const path = history.location.pathname;
  const [selectedMenu, setSelectedMenu] = useState<MenuType>();
  const [navigatorShadow, setNavigatorShadow] =
    useRecoilState(navigatorShadowState);
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  let lastScrollTop = 0;
  const menuRef = useRef<{ [key in MenuType]: HTMLDivElement | null }>({
    home: null,
    like: null,
    search: null,
    mypage: null,
    wallet: null,
    name: null,
    seed: null,
    rank: null,
    alarm: null,
  });
  const [sliderLeft, setSliderLeft] = useState(25);
  const [sliderWidth, setSliderWidth] = useState(84);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      // home 메뉴일 때만 스크롤 제어
      if (selectedMenu !== "home") return;

      const element = e.target as HTMLElement;
      const st = element.scrollTop;
      // 아래로 스크롤 시도할 때
      if (st < 0 && st < lastScrollTop) {
        element.scrollTop = lastScrollTop;
      }

      lastScrollTop = st <= 0 ? 0 : st;
    };

    const handleTouchMove = (e: TouchEvent) => {
      // home 메뉴일 때만 터치 제어
      if (selectedMenu !== "home") return;

      const element = mainWrapperRef.current;
      if (
        element &&
        element.scrollTop < 0 &&
        element.scrollTop >= element.scrollHeight - element.clientHeight
      ) {
        e.preventDefault();
      }
    };

    const mainWrapper = mainWrapperRef.current;
    if (mainWrapper) {
      mainWrapper.addEventListener("scroll", handleScroll);
      mainWrapper.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      if (mainWrapper) {
        mainWrapper.removeEventListener("scroll", handleScroll);
        mainWrapper.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [selectedMenu]);

  useEffect(() => {
    setNavigatorShadow(true);
    if (path === "/") {
      history.push("/home");
    } else {
      const tab = path.split("/")[1] as MenuType;
      setSelectedMenu(tab ? tab : "home");
    }
  }, [path]);

  useEffect(() => {
    if (menuRef.current[selectedMenu!]) {
      const selectedItem = menuRef.current[selectedMenu!];
      const selectedItemOffsetLeft = selectedItem?.offsetLeft || 0;
      const selectedItemWidth = selectedItem?.offsetWidth || 60;
      setSliderLeft(selectedItemOffsetLeft);
      setSliderWidth(selectedItemWidth);
    }
  }, [selectedMenu]);

  const onClickMenu = (menu: MenuType) => {
    if (selectedMenu === menu) {
      return;
    }

    const state: { [key: string]: any } = {};
    if (menu === "search") {
      state.mode = "query";
    }

    history.push(`/${menu}`, state);
  };

  const renderContent = useCallback(() => {
    return (
      <Suspense
        fallback={
          <div className="loading-spinner">
            <img src="/image-web/loading.gif" alt="loading" />
          </div>
        }
      >
        {selectedMenu === "home" && <Home />}
        {selectedMenu === "search" && <Search />}
        {selectedMenu === "name" && <Search initialShowSearch={true} />}
        {selectedMenu === "mypage" && <Index />}
        {selectedMenu === "like" && <Favorite />}
        {selectedMenu === "wallet" && <UseCafe />}
        {selectedMenu === "rank" && <Rank />}
        {selectedMenu === "alarm" && <Alarm />}
      </Suspense>
    );
  }, [selectedMenu]);

  return (
    <MainWrapper ref={mainWrapperRef}>
      {renderContent()}
      <BottomNavigationWrapper $isShadow={navigatorShadow}>
        <div
          className={`item ${selectedMenu === "home" ? "selected" : ""}`}
          ref={(el) => (menuRef.current.home = el)}
          onClick={() => onClickMenu("home")}
        >
          <div className="icon-wrapper">
            <img
              className={`icon ${
                selectedMenu === "home" ? "selected" : "unselected"
              }`}
              src={`/image-web/bottom-nav/renewal/new-home${
                selectedMenu === "home" ? "-active" : ""
              }.svg`}
              alt="홈"
            />
          </div>
          <div
            className={`name ${
              selectedMenu === "home" ? "selected" : "unselected"
            }`}
          >
            홈
          </div>
        </div>

        <div
          className={`item ${
            selectedMenu === "wallet" || selectedMenu === "seed"
              ? "selected"
              : ""
          }`}
          ref={(el) => (menuRef.current.wallet = el)}
          onClick={() => onClickMenu("wallet")}
        >
          <div className="icon-wrapper">
            <img
              className={`icon`}
              src={`/image-web/bottom-nav/renewal/new-wallet${
                selectedMenu === "wallet" || selectedMenu === "seed"
                  ? "-active"
                  : ""
              }.svg`}
              alt="내지갑"
            />
          </div>
          <div
            className={`name ${
              selectedMenu === "wallet" || selectedMenu === "seed"
                ? "selected"
                : "unselected"
            }`}
          >
            지갑
          </div>
        </div>

        <div
          className={`item ${selectedMenu === "rank" ? "selected" : ""}`}
          ref={(el) => (menuRef.current.rank = el)}
        >
          <div className="icon-wrapper" onClick={() => onClickMenu("rank")}>
            <img
              className={`icon ${
                selectedMenu === "rank" ? "selected" : "unselected"
              }`}
              src={`/image-web/bottom-nav/renewal/new-rank${
                selectedMenu === "rank" ? "-active" : ""
              }.svg`}
              alt="랭크"
            />
          </div>
          <div
            className={`name ${
              selectedMenu === "rank" ? "selected" : "unselected"
            }`}
          >
            랭킹
          </div>
        </div>

        <div
          className={`item ${selectedMenu === "mypage" ? "selected" : ""}`}
          ref={(el) => (menuRef.current.mypage = el)}
          onClick={() => onClickMenu("mypage")}
        >
          <div className="icon-wrapper">
            <img
              className={`icon`}
              src={`/image-web/bottom-nav/renewal/new-my${
                selectedMenu === "mypage" ? "-active" : ""
              }.svg`}
              alt="마이페이지"
            />
          </div>
          <div
            className={`name ${
              selectedMenu === "mypage" ? "selected" : "unselected"
            }`}
          >
            마이러너
          </div>
        </div>
      </BottomNavigationWrapper>
    </MainWrapper>
  );
};
export default Main;
