import { useHistory } from "react-router-dom";
import { AlarmWrapper, Header } from "./style";
import AlarmList from "./component/AlarmList";
import { isApp } from "../../../hooks/useNativeApp";
const Alarm = () => {
  const history = useHistory();
  return (
    <AlarmWrapper isApp={false}>
      <Header isApp={false}>
        <div className="right">
          <div className="close" onClick={() => history.push("/")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 19L5 12M5 12L12 5M5 12H19"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="title">알림</div>
        </div>
        <div className="left">
          <img
            src="image-web/home/coupon-black.svg"
            alt="쿠폰"
            onClick={() => history.push("/mypage?menu=coupon")}
          />
          <div className="alarm" onClick={() => history.push("/alarm")}>
            <img src="image-web/home/alarm-black.svg" alt="알람" />
          </div>
        </div>
      </Header>
      <AlarmList />
    </AlarmWrapper>
  );
};

export default Alarm;
