import { useCallback, useEffect, useState } from "react";
import { FranchiseHeader, FranchisePubsWrapper } from "./style";
import { Cafe, PubTournament } from "../../../api/types";
import {
  cafeTournaments,
  getFranchisePub,
  searchNestCafe,
} from "../../../api/cafe";
import { FranchiseItem } from "./component/FranchiseItem";
import { useHistory } from "react-router-dom";
import FranchiseMore from "./component/franchiseMore";
import useGeoLocation from "../../../hooks/useGeoLocation";
import { isApp } from "../../../hooks/useNativeApp";

const FranchisePubs = () => {
  const [allList, setAllList] = useState<Cafe[]>([]);
  const [list, setList] = useState<Cafe[]>([]);
  const [selectedStore, setSelectedStore] = useState<Cafe | null>(null);
  const [showMoreOption, setShowMoreOption] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const { latitude, longitude } = useGeoLocation();
  const history = useHistory();
  const handleBackClick = () => history.goBack();

  const updateCafeListWithTournaments = useCallback(
    (cafeList: Cafe[], tournamentList: PubTournament[]): Cafe[] => {
      // tournamentList를 cafeId를 키로 하는 Map으로 변환
      const tournamentMap = new Map(
        tournamentList.map((tournament) => [tournament.cafeId, tournament])
      );
      // 각 카페에 해당하는 토너먼트 정보 업데이트
      const data = cafeList.map((cafe) => {
        const tournament = tournamentMap.get(+cafe.id);
        return {
          ...cafe,
          pubTournamentList: tournament ? [tournament] : [],
        };
      });

      return data;
    },
    []
  );

  const getPubList = async () => {
    const res = await getFranchisePub(latitude, longitude);

    const tournamentList = await cafeTournaments(res.list);
    if (tournamentList.length > 0) {
      const updatedList = updateCafeListWithTournaments(
        res.list,
        tournamentList
      );
      setList(updatedList);
      setAllList(updatedList);
      return;
    }
    setList(res.list);
    setAllList(res.list);
  };

  const handleSearch = () => {
    if (search.trim() === "") {
      setList(allList);
    } else {
      const filteredList = allList.filter((item) =>
        item.cafeName.toLowerCase().includes(search.toLowerCase())
      );
      setList(filteredList);
    }
  };

  useEffect(() => {
    getPubList();
  }, []);

  return (
    <FranchisePubsWrapper isApp={false}>
      <FranchiseHeader isApp={false}>
        <div className="close" onClick={handleBackClick}>
          <img
            src="/image-web/Icon/Back.svg"
            id="홀덤펍 찾기 뒤로가기"
            alt="close"
          />
        </div>
        <div className="input-wrapper">
          <input
            placeholder="홀덤 펍을 검색해보세요"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <img
            src="image-web/search/search-icon.svg"
            alt="icon"
            onClick={() => handleSearch()}
          />
        </div>
      </FranchiseHeader>
      {showMoreOption && selectedStore && (
        <FranchiseMore
          cafe={selectedStore}
          setShowMoreOption={setShowMoreOption}
          setSelectedStore={setSelectedStore}
          showMoreOption={showMoreOption}
        />
      )}
      <div className="list-wrapper">
        <div className="header">러너펍</div>
        {list.map((item, index) => (
          <FranchiseItem
            item={item}
            key={index}
            setSelectedStore={setSelectedStore}
            setShowMoreOption={setShowMoreOption}
          />
        ))}
      </div>
    </FranchisePubsWrapper>
  );
};

export default FranchisePubs;
