import instance from "../utils/network";
export interface NotificationListResponse {
  list: NotificationResponse[]
}
export interface NotificationResponse {
  id: number;
  type: NotificationType;
  title?:string;
  content:string;
  isRead:boolean;
  elapsedTime: number;
}

export enum NotificationType {
  SYSTEM = 'system',
  COMMENT = 'comment',
  WALLET= 'wallet',
}


export function getNoticesListAndRead(): Promise<NotificationListResponse> {
  return instance.get(`/nest/user-notifications/list`);
}
