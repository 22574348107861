import {
  DialogWrapper,
  FormContainer,
  ContentWrapper,
  ButtonWrapper,
} from "../style/PopupStyles";

interface ReservationEndProps {
  onClose: () => void;
  tournamentTitle: string;
  keepSeed: number;
  type: string;
}

const ReservationEnd = ({
  onClose,
  tournamentTitle,
  keepSeed,
  type,
}: ReservationEndProps) => {
  return (
    <DialogWrapper>
      <FormContainer>
        <ContentWrapper>
          <div className="content">
            {type === "reservation" ? (
              <>
                {keepSeed} RABBIT을 소진하여
                <br />
                {tournamentTitle}
                <br />
                예약(Entry)이 완료되었습니다.
              </>
            ) : (
              <>
                {tournamentTitle}
                <br />
                예약(Entry)이 취소되었습니다.
                <br />+ {keepSeed} RABBIT
              </>
            )}
          </div>
        </ContentWrapper>
        <ButtonWrapper>
          <div className="button purple" onClick={() => onClose()}>
            확인
          </div>
        </ButtonWrapper>
      </FormContainer>
    </DialogWrapper>
  );
};

export default ReservationEnd;
