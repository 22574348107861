import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../../utils/network";
import { useGameDialog } from "./dialogHook";
import { useUserData } from "./useUseData";
import { useTournamentData } from "./useTournamentData";
import { useGameActions } from "./useGameAction";
import { useRankingPoint } from "./useRankingPoint";
import { useRankingDialog } from "./useRankingDialog";
import { useGameInfoDialog } from "./useGameInfoDialog";
import { useAttendanceDialog } from "./useAttendanceDialog";
import { useGameCouponDialog } from "./gameCouponDialogHook";
import { getGameInspectionSchedule } from "../../../../api/game-inspection-schedule";
import { enqueueSnackbar } from "notistack";
import { formatDate } from "../../../../utils/date";

export enum GamePage {
  MAIN,
  RANKING,
  GAME,
  MY_PAGE,
}

export interface GameDialogProps {
  text?: string;
  buttonText?: string;
  onConfirm?: (closeFn?: () => void) => void;
  onCancel?: () => void;
}

export const useGame = () => {
  const history = useHistory();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
  const [gamePageState, setGamePageState] = useState(GamePage.MAIN);
  const { user, setUser, refreshUser, userTicketInfo, getUserTicket } =
    useUserData();
  const [code, setCode] = useState("");
  const tournamentData = useTournamentData(gamePageState);
  const rankingPointHook = useRankingPoint();
  const gameDialog = useGameDialog();
  const gameCouponDialog = useGameCouponDialog();
  const gameAction = useGameActions(
    user,
    userTicketInfo,
    accessToken,
    tournamentData.getDailyTournament
  );
  const rankingDialog = useRankingDialog();
  const gameInfoDialog = useGameInfoDialog();
  const attendanceDialog = useAttendanceDialog();

  const getGameInspection = () => {
    getGameInspectionSchedule().then((res) => {
      if (res.isInspection) {
        enqueueSnackbar(
          `게임 점검 진행 중입니다.\n점검 종료시간 : ${formatDate(res.endAt)}`,
          {
            variant: "warning",
            style: {
              whiteSpace: "pre-line",
            },
          }
        );
        history.replace("/home");
      }
    });
  };

  useEffect(() => {
    getGameInspection();
  }, []);

  return {
    history,
    //유저 정보
    user,
    setUser,
    refreshUser,
    userTicketInfo,
    getUserTicket,
    //랭킹
    ...rankingPointHook,
    //페이지 상태
    gamePageState,
    setGamePageState,
    //토너먼트 관련
    ...tournamentData,
    //다이얼로그 관련
    ...gameDialog,
    ...rankingDialog,
    ...gameInfoDialog,
    ...attendanceDialog,
    ...gameCouponDialog,
    //게임 관련
    ...gameAction,
    //쿠폰 관련
    code,
    setCode,
    ...gameCouponDialog,
    //게임 관련
    ...gameAction,
    ...attendanceDialog,
    getGameInspection,
  };
};
