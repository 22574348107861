import styled from "styled-components";

export const FranchisePubsWrapper = styled.div<{ isApp: boolean }>`
  height: 100vh;
  width: 100%;
  overflow: auto;
  padding-bottom: ${(p) => (p.isApp ? "60px" : "30px")};
  margin-top: ${(p) => (p.isApp ? "44px" : "0px")};
  > .list-wrapper {
    > .header {
      padding: 0 16px;
      font-family: Pretendard;
      font-weight: 800;
      font-size: 20px;
    }

    display: flex;
    gap: 12px;
    flex-direction: column;
    margin-top: 68px;
  }
`;

export const FranchiseHeader = styled.div<{ isApp: boolean }>`
  top: ${(p) => (p.isApp ? "43px" : "0px")};
  position: fixed;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 0px 20px;
  z-index: 11;
  background: white;
  gap: 8px;

  > .close {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  > .input-wrapper {
    position: relative;
    width: 100%;
    height: 35px;

    > input {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      padding-top: 10px;
      padding-right: 12px;
      padding-bottom: 10px;
      padding-left: 12px;
      background: #f0f0f0;
      outline: none;
      border: none;
      box-sizing: border-box;
      font-family: Pretendard;
      font-weight: 600;
      font-size: 12px;
    }

    > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }
`;

export const PremiumStoreInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  gap: 12px;
  width: calc(100% - 32px);
  height: 162px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  > .thumbnail {
    width: 100%;
    height: 162px;
    background: gray;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      padding: 1px;
      background: linear-gradient(
        259.19deg,
        rgba(0, 0, 0, 0.3) 42.53%,
        #000000 83.07%
      );
    }
    > .cover {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const PremiumStoreInfoBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 16px 12px;

  > .state {
    position: absolute;
    top: 84px;
    left: 12px;
    color: #fff;
    font-family: Pretendard;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 2.5px 5px;
    border-radius: 6px;
    background: #6436e7;
  }

  > .info {
    width: 90%;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px 10px;
    backdrop-filter: blur(2px);
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    color: white;

    > .address {
      width: 90%;
      font-family: Pretendard;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: -2%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .info-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 8px;

      > .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2px;

        > .icon {
          width: 16px;
          height: 16px;
        }

        > .text {
          color: #fff;
          font-family: Pretendard;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.22px;
        }
      }
    }
  }
`;
export const PremiumStoreCircle = styled.svg`
  position: absolute;
  transform: rotate(0.25turn);
  width: 57px;
  height: 57px;
  background: #1b143f;
  border-radius: 438px;

  circle {
    fill: none;
    stroke-width: 8;
  }

  .background {
    stroke: #6d6d6d;
  }

  .progress {
    transition: stroke-dashoffset 0.35s;
    transform: scaleX(-1);
    transform-origin: center;
  }
`;

export const PremiumStoreTournamentInfoWrapper = styled.div<{ isIng: boolean }>`
  position: absolute;
  right: 16px;
  bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 57px;
  border-radius: 57px;
  background: #d9d9d9;
  > .timer-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    > .time {
      color: #fff;
      font-family: Pretendard;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.22px;
    }

    > .lv {
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.18px;
    }
  }

  > span {
    position: absolute;
    color: #7d7d7d;
    text-align: center;
    font-family: Pretendard;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }
`;

export const PubMoreWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 20px 50px;
  z-index: 9999;
  background-color: white;
  border-radius: 12px 12px 0px 0px;

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    > .tag-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > img {
        width: 20px;
      }
      > .tag-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        > .tag {
          padding: 4px 6px;
          color: var(--Purple-300, #6436e7);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 16.667px;
          border: 1px solid ${(p) => p.theme.color.purple100};
          white-space: nowrap;
        }
      }
      > .review-box {
        display: flex;
        flex-direction: row;
        > .premium {
          width: 13px;
          height: 13px;
          top: 8px;
          left: 8px;
        }
        > .review-count {
          width: 18px;
          text-align: center;
          font-family: Pretendard;
          margin-left: 1px;
          font-weight: 700;
          font-size: 12px;
          margin-bottom: 2px;
          font-style: normal;
        }
      }
    }
    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      text-align: center;
      > .name {
        margin-top: 8px;
        color: ${(p) => p.theme.color.black400};
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }
      > img {
        pointer-events: visible;
        width: 16px;
        height: 16px;
      }
    }

    > .address {
      margin-top: 4px;
      color: ${(p) => p.theme.color.black300};
      font-family: Pretendard;

      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: -2%;
    }
    > .status-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;

      > span {
        color: ${(p) => p.theme.color.black300};
        font-family: Pretendard;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > span.bold {
        color: ${(p) => p.theme.color.black400};
      }
    }
    > .info-row {
      margin-top: 4px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 8px;

      > .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1px;

        > .icon {
          filter: grayscale(100%) opacity(50%);
          width: 16px;
          height: 16px;
        }

        > .text {
          color: #868686;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
    }
  }
  > .menu-wrapper {
    width: 100%;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .item {
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid var(--Black-100, #f0f0f0);
      width: var(--Pro2, 72px);
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;

      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      > span {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }

  > .menu-wrapper.width60 {
    > .item {
      width: 60px;
    }
  }

  > .box {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &:first-child {
      margin-top: 0;
    }

    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      > .button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;

        > img {
          cursor: pointer;
          object-fit: contain;
          width: 16px;
          height: 16px;
        }
      }
    }

    > .content-wrapper {
      padding: 16px 12px 16px 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      flex-grow: 1;
      border-radius: 8px;
      background: var(--Black-100, #f0f0f0);

      > .content-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        > .content {
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 18.2px */
          letter-spacing: -0.26px;
          word-break: break-word;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          white-space: pre-wrap;
        }

        > .admin-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;

          > .button {
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid var(--Black-200, #b7b7b7);
            padding: 6px 16px;
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        > .createdAt {
          color: var(--Black-200, #b7b7b7);
          text-align: right;
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.26px;
        }
      }

      > img {
        height: 99px;
        flex-shrink: 0;
        width: 70px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid #fff;
        background: url(<path-to-image>) lightgray 50% / contain no-repeat;
      }
    }
  }
`;
export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 반투명한 검정색 */
  z-index: 9998; /* 팝업 바로 뒤에 오도록 설정 */
`;
