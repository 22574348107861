import React from "react";
import { Header, AddressBar } from "./Style/SearchStyles";
import { useUI } from "./Hook/UIContext";
import { useHistory } from "react-router-dom";
import LocationSheet from "../../../components/web/LocationSheet";
import { isApp } from "../../../hooks/useNativeApp";

const HeaderContent: React.FC = () => {
  const history = useHistory();
  const { showStoreList, setShowSearch, isScrolled } = useUI();

  const handleBackClick = () => history.goBack();

  return (
    <>
      <Header>
        <AddressBar show={showStoreList} isScrolled={isScrolled} isApp={false}>
          <div className="close" onClick={handleBackClick}>
            <img
              src="/image-web/Icon/Back.svg"
              id="홀덤펍 찾기 뒤로가기"
              alt="close"
            />
          </div>
          <input
            className={`box ${!showStoreList ? "map " : ""}flex`}
            readOnly
            placeholder="홀덤 펍 검색"
            onClick={() => setShowSearch(true)}
          />
        </AddressBar>
      </Header>
      <LocationSheet />
    </>
  );
};

export default HeaderContent;
