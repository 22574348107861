import { enqueueSnackbar } from "notistack";
import {
  CertBottomButton,
  CertBottomInfo,
  CertInputWrapper,
  CertTitle,
  CertWrapper,
} from "./styles/cert";
import { isApp } from "../../../hooks/useNativeApp";

interface StepProps {
  certName: string;
  setCertName: (name: string) => void;
  onNext: () => void;
}

const StepCertName = ({ certName, setCertName, onNext }: StepProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 입력 시에는 모든 문자 허용
    const value = e.target.value;
    setCertName(value);
  };

  const isKorean = (text: string) => {
    // 완성형 한글만 허용하는 정규식
    const koreanOnly = /^[가-힣\s]+$/;
    return koreanOnly.test(text);
  };

  const handleNext = () => {
    if (certName.trim().length === 0) {
      enqueueSnackbar("이름을 입력해 주세요.", {
        variant: "error",
      });
      return;
    }

    if (!isKorean(certName)) {
      enqueueSnackbar("한글로 이름을 입력해주세요.", {
        variant: "error",
      });
      return;
    }

    onNext();
  };

  return (
    <CertWrapper isApp={false}>
      <CertTitle>이름을 입력해 주세요.</CertTitle>
      <CertInputWrapper>
        <input
          type="text"
          value={certName}
          onChange={handleInputChange}
          placeholder="이름을 입력해주세요."
        />
      </CertInputWrapper>
      <CertBottomButton onClick={handleNext}>다음</CertBottomButton>
      <CertBottomInfo>
        PASS 인증을 하지 않은 계정의 경우, 매장 이용 시 본인 확인
        <br /> (신분증, 여권 등) 정보와 일치하지 않으면 매장 이용이
        불가능합니다.
      </CertBottomInfo>
    </CertWrapper>
  );
};

export default StepCertName;
