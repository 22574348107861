import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "../StoreContext";
import { BoxWrapper, DetailInfoBox } from "./styles/DetailInfo";
interface InfoBoxProps {
  boxRef: RefObject<HTMLDivElement>;
}

const DetailInfo = ({ boxRef }: InfoBoxProps) => {
  const { data } = useContext(StoreContext);
  const [isFold, setIsFold] = useState(true);
  const [infoUseRef, setInfoUseRef] = useState<HTMLDivElement | null>(null);
  const [showFoldButton, setShowFoldButton] = useState(false);

  useEffect(() => {
    if (infoUseRef) {
      new ResizeObserver(() => {
        const scrollHeight = infoUseRef.scrollHeight;
        const offsetHeight = infoUseRef.offsetHeight;
        setShowFoldButton(!isFold || scrollHeight > offsetHeight);
      }).observe(infoUseRef);
    }
  }, [infoUseRef, isFold]);

  return (
    <DetailInfoBox ref={boxRef}>
      <BoxWrapper>
        <div className="title">매장 이용 안내</div>
        <div className="box-wrapper-outer">
          <div className={"box-wrapper " + (isFold ? "fold" : "")}>
            <div
              ref={setInfoUseRef}
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.informationUse.replace(/\n/g, "<br/>"),
              }}
            />
            {showFoldButton && (
              <img
                src="/image-web/store/Arrow%20down.svg"
                className="button"
                onClick={() => setIsFold(!isFold)}
              />
            )}
          </div>
        </div>
      </BoxWrapper>

      <BoxWrapper>
        <div className="title">업체 정보</div>
        <div className="box-wrapper-outer">
          <div className="box-wrapper">
            <div className="content">
              상호: {data.cafeName}
              <br />
              주소: {data.newAddress} {data.detailAddress}
              <br />
              전화번호: {data.vcn}
              <br />
              사업자번호: {data.employerId || "-"}
            </div>
          </div>
        </div>
      </BoxWrapper>
    </DetailInfoBox>
  );
};
export default DetailInfo;
