import React, { useEffect } from "react";
import { SearchLayout, MapView, MapPub } from "./Components";
import { useUI } from "./Hook/UIContext";
import { useStore } from "./Hook/StoreContext";

const StoreListContent: React.FC = () => {
  const {
    showFilter,
    showLocationFilter,
    setShowLocationFilter,
    showStoreList,
    setShowStoreList,
  } = useUI();
  const { selectedStore } = useStore();

  useEffect(() => {
    setShowStoreList(false);
  }, []);

  return (
    <>
      <SearchLayout
        showFilter={showFilter}
        showLocationFilter={showLocationFilter}
        setShowLocationFilter={setShowLocationFilter}
      >
        {!showStoreList && <MapView />}
      </SearchLayout>
      {!showStoreList && selectedStore && <MapPub cafe={selectedStore} />}
    </>
  );
};

export default StoreListContent;
