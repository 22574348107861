import { useCallback, useState } from "react";

export interface GameCouponDialogProps {
  onConfirm?: (closeFn?: () => void) => void;
  onCancel?: () => void;
}

export const useGameCouponDialog = () => {
  const [couponDialogState, setCouponDialogState] =
    useState<GameCouponDialogProps | null>(null);

  const handleClose = useCallback(() => {
    setCouponDialogState(null);
  }, []);

  const handleOpen = useCallback((props: GameCouponDialogProps) => {
    const dialogProps: GameCouponDialogProps = {
      ...props,
      onConfirm: () => {
        props.onConfirm && props.onConfirm();
        handleClose();
      },
    };
    setCouponDialogState(dialogProps);
  }, []);

  return {
    openCouponDialog: handleOpen,
    closeCouponDialog: handleClose,
    couponDialogState,
  };
};
