import { OpenAppContent, OpenAppWrapper } from "../style/OpenApp";

interface OpenAppProps {
  openApp: () => void;
  onClose: () => void;
}

const OpenApp = ({ openApp, onClose }: OpenAppProps) => {
  return (
    <>
      <OpenAppWrapper onClick={onClose} />
      <OpenAppContent>
        <div className="title">
          모바일 앱에서 예약이 가능합니다. <br />
          앱으로 이동하시겠습니까?
        </div>
        <div className="button-wrapper">
          <div className="button cancel" onClick={onClose}>
            아니오, 웹으로 볼래요.
          </div>
          <div
            className="button open"
            onClick={() => {
              openApp();
              onClose();
            }}
          >
            앱 열기
          </div>
        </div>
      </OpenAppContent>
    </>
  );
};

export default OpenApp;
