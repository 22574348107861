import instance from "../utils/network";
import { CommonResponse, ReservationResponse } from "./types";

export interface CompanyReservationParams {
  reservationId: string;
  userId: number;
  root: string;
}

export function registerCompanyReservation(
  data: CompanyReservationParams
): Promise<CommonResponse> {
  return instance.post("/seller/reservation-company/register", data);
}

export function cancelCompanyReservation(
  id: number
): Promise<CommonResponse> {
  return instance.delete(`/seller/reservation-company/cancel/${id}`);
}

export function getCompanyReservation(id: string): Promise<ReservationResponse> {
  return instance.get("/seller/reservation-company/" + id);
}

export function getCompanyCurrentReservations(
  id: string
): Promise<{ nickname: string, id: number }[]> {
  return instance.get(`/seller/reservation-company/current_nicknames/${id}`);
}
