import {
  LoginToNaverButtonWrapper,
  LoginToNaverOverlay,
  LoginToNaverTitle,
  LoginToNaverWrapper,
} from "./style";

interface props {
  onClose: () => void;
  handleNaverLogout: () => void;
  naverEmail: string;
  type: string;
}

const LoginToNaver = ({
  onClose,
  handleNaverLogout,
  naverEmail,
  type,
}: props) => {
  return (
    <>
      <LoginToNaverOverlay />
      <LoginToNaverWrapper>
        <LoginToNaverTitle>
          {type === "login" ? (
            <>
              기존에 가입된 {naverEmail}
              <br />
              으로 로그인 하시겠습니까?
            </>
          ) : (
            <>
              {naverEmail}
              <br />
              으로 회원가입 하시겠습니까?
            </>
          )}
        </LoginToNaverTitle>
        <LoginToNaverButtonWrapper>
          <div
            className="button"
            onClick={() => {
              handleNaverLogout();
            }}
          >
            연결해제
          </div>
          <div
            className="button purple"
            onClick={() => {
              onClose();
            }}
          >
            확인
          </div>
        </LoginToNaverButtonWrapper>
      </LoginToNaverWrapper>
    </>
  );
};

export default LoginToNaver;
