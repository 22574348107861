import styled from "styled-components";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { Swiper as SwiperClass } from "swiper/types";
import useScreenOrientation, {
  MEDIA_DESKTOP,
} from "../hooks/useScreenOrientation";
import { ReviewImageInterface } from "./ImageViewDndItem";
import { isMobile } from "react-device-detect";

// ImageViewerModal에서 필요한 스타일 컴포넌트들
const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1010; // ImageViewer보다 위에 표시
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  touch-action: none;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  color: white;
  border-radius: 9999px;
  transition: background-color 0.2s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1011;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    top: 8px;
    right: 8px;
  }
`;

const CloseIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 6L6 18" />
    <path d="M6 6l12 12" />
  </svg>
);

const ZoomInfo = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1060;
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: 768px) {
    bottom: 8px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (pointer: fine) {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;

interface StyledImageProps {
  $x: number;
  $y: number;
  $scale: number;
  $isLoading: boolean;
  $transition: boolean;
}

const StyledImage = styled.img<StyledImageProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(
      calc(-50% + ${(props) => props.$x}px),
      calc(-50% + ${(props) => props.$y}px)
    )
    scale(${(props) => props.$scale});
  transition: ${(props) =>
    props.$transition ? "transform 0.2s, opacity 0.3s" : "opacity 0.3s"};
  opacity: ${(props) => (props.$isLoading ? 0 : 1)};
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
`;

// ImageViewer에서 사용하는 스타일 컴포넌트들
const Dim = styled.div`
  @media ${MEDIA_DESKTOP} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: #000;
    z-index: 110;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000;
  display: flex;
  flex-direction: column;
  @media ${MEDIA_DESKTOP} {
    width: 900px;
    height: 674px;
    border-radius: 20px;
    background: var(--Black-100, #f0f0f0);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .title {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: block;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }

  @media ${MEDIA_DESKTOP} {
    width: 100%;
    height: 64px;
    background: #fff;
    padding: 0 20px;
  }

  .close {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 20px;

    > img {
      width: 24px;
      height: 24px;
      line-height: 1;
    }
  }

  .indicator {
    display: flex;
    gap: 2px;
    padding: 0 20px;
    align-items: center;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }

    span {
      font-size: 16px;
      color: var(--Black-300, #808080);
    }

    span:first-child {
      color: #fff;
      font-weight: 600;
    }

    > .toggle-mode {
      display: flex;
      width: 54px;
      height: 36px;
      padding: 9px 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 8px;
      background: var(--Purple-300, #6436e7);
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      margin-left: 12px;
    }
  }
`;

const ImageView = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  .swiper {
    height: 100%;
  }

  img.content {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media ${MEDIA_DESKTOP} {
    position: relative;
  }
`;

const ArrowButton = styled.div<{
  direction: "left" | "right";
}>`
  cursor: pointer;
  display: none;
  @media ${MEDIA_DESKTOP} {
    display: block;
  }
  width: 44px;
  height: 44px;
  position: absolute;
  border-radius: 22px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  top: 50%;
  ${(props) => (props.direction === "left" ? "left: 26px;" : "right: 26px;")}
  transform: translateY(-50%);

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    ${(props) =>
      props.direction === "left" ? "transform: rotate(180deg);" : ""}
  }

  z-index: 11;
`;

// 확대 버튼 스타일 컴포넌트 추가
const ZoomButton = styled.button`
  position: absolute;
  pointer-events: none;
  bottom: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 20;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 768px) {
    bottom: 12px;
    right: 12px;
  }
`;

// 확대 아이콘 컴포넌트
const ZoomIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
    <line x1="11" y1="8" x2="11" y2="14" />
    <line x1="8" y1="11" x2="14" y2="11" />
  </svg>
);

const PreviewWrapper = styled.div`
  width: 100%;
  @media ${MEDIA_DESKTOP} {
    padding: 12px 0;
    background: #fff;
  }

  .swiper {
    height: 100%;

    .swiper-slide {
      width: auto;
      height: auto;
    }
  }

  .thumbnail {
    position: relative;
    width: 122px;
    height: 90px;
    @media ${MEDIA_DESKTOP} {
      width: 86px;
      height: 86px;
      flex-shrink: 0;
    }

    .delete {
      cursor: pointer;
      position: absolute;
      top: 6px;
      right: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;

      img {
        width: 30px;
        height: 30px;
      }
    }

    &[data-selected="true"] {
      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: " ";
        box-shadow: 0 0 0 2px var(--Purple-300, #6436e7) inset;
        pointer-events: none;
        user-select: none;
        @media ${MEDIA_DESKTOP} {
          border-radius: 12px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media ${MEDIA_DESKTOP} {
        border-radius: 12px;
      }
    }
  }
`;

const Indicator = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  border-radius: 12px 0px 0px 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 4px 16px;
  color: var(--Black-200, #b7b7b7);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 20;

  > .white {
    color: #fff;
    font-weight: 600;
  }

  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  @media ${MEDIA_DESKTOP} {
    display: flex;
  }
`;

const ModeIndicator = styled.div`
  display: none;
  flex-direction: row;
  border-radius: 8px;
  background: var(--Black-100, #f0f0f0);
  overflow: hidden;

  > .item {
    cursor: pointer;
    transition: all 0.3s;
    padding: 7px 12px;
    border-radius: 8px;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }

  > .item.selected {
    color: #fff;
    background: var(--Purple-300, #6436e7);
  }

  @media ${MEDIA_DESKTOP} {
    display: flex;
  }
`;

// 이미지 컨테이너를 위한 래퍼 추가
const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface EnhancedImageViewerProps {
  images: string[];
  reviewImages?: ReviewImageInterface[];
  defaultIndex?: number;
  onClose: () => void;
  canEdit?: boolean;
  onChangeOrder?: (images: string[]) => void;
  onDeleteImage?: (index: number) => void;
  onClickEdit?: () => void;
}

function EnhancedImageViewer({
  images,
  defaultIndex,
  onClose,
  canEdit,
  reviewImages,
  onChangeOrder,
  onDeleteImage,
  onClickEdit,
}: EnhancedImageViewerProps) {
  const orientation = useScreenOrientation();
  const mainSwiperRef = useRef<SwiperRef>(null);
  const previewSwiperRef = useRef<SwiperRef>(null);
  const [mode, setMode] = useState<"normal" | "edit">("normal");
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex || 0);

  // 확대/축소 모달 관련 상태
  const [zoomModalOpen, setZoomModalOpen] = useState(false);
  const [zoomScale, setZoomScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [useTransition, setUseTransition] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const initialTouchDistance = useRef<number | null>(null);
  const lastTouchDistance = useRef<number | null>(null);

  const handleSlide = useCallback((e: SwiperClass) => {
    setSelectedIndex(e.activeIndex);

    if (previewSwiperRef.current) {
      previewSwiperRef.current.swiper.slideTo(e.activeIndex);
    }
  }, []);

  const handleClickPreview = (index: number) => {
    setSelectedIndex(index);
  };

  const handleClickEdit = () => {
    onClickEdit && onClickEdit();
  };

  // 이미지 클릭 시 확대/축소 모달 열기 - 제거됨
  // 이제 확대 버튼을 통해서만 확대/축소 모달 열기
  const handleZoomButtonClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    setZoomModalOpen(true);
    setIsLoading(true);
    setZoomScale(1);
    setPosition({ x: 0, y: 0 });
  }, []);

  // 확대/축소 모달 닫기
  const handleZoomModalClose = useCallback(() => {
    setZoomModalOpen(false);
  }, []);

  // 이미지 로드 완료 시 처리
  const handleImageLoad = useCallback(() => {
    setIsLoading(false);
    if (imageRef.current && containerRef.current) {
      const imgWidth = imageRef.current.naturalWidth;
      const imgHeight = imageRef.current.naturalHeight;
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      const widthRatio = containerWidth / imgWidth;
      const heightRatio = containerHeight / imgHeight;

      const initialScale = Math.min(widthRatio, heightRatio);
      setZoomScale(initialScale);
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !zoomModalOpen) return;

    const handleWheelEvent = (e: WheelEvent) => {
      e.preventDefault();
      const rect = container.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const delta = e.deltaY * -0.01;
      const newScale = Math.min(Math.max(0.5, zoomScale + delta), 5);

      if (zoomScale !== newScale) {
        const scaleDiff = newScale - zoomScale;
        const newX =
          position.x - ((x - rect.width / 2) * scaleDiff) / zoomScale;
        const newY =
          position.y - ((y - rect.height / 2) * scaleDiff) / zoomScale;

        setZoomScale(newScale);
        setPosition({ x: newX, y: newY });
      }
    };

    // wheel 이벤트 리스너를 non-passive로 설정
    container.addEventListener("wheel", handleWheelEvent, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleWheelEvent);
    };
  }, [zoomModalOpen, zoomScale, position]);

  // 터치 시작
  const handleTouchStart = useCallback(
    (e: React.TouchEvent) => {
      if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        initialTouchDistance.current = distance;
        lastTouchDistance.current = distance;
        setUseTransition(false);
      } else if (e.touches.length === 1) {
        setIsDragging(true);
        setDragStart({
          x: e.touches[0].clientX - position.x,
          y: e.touches[0].clientY - position.y,
        });
        setUseTransition(true);
      }
    },
    [position]
  );

  // 터치 이동
  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (e.touches.length === 2 && initialTouchDistance.current !== null) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );

        if (lastTouchDistance.current !== null) {
          const delta = distance - lastTouchDistance.current;
          const newScale = Math.min(Math.max(0.5, zoomScale + delta * 0.01), 5);

          if (containerRef.current && zoomScale !== newScale) {
            const rect = containerRef.current.getBoundingClientRect();
            const centerX = (touch1.clientX + touch2.clientX) / 2;
            const centerY = (touch1.clientY + touch2.clientY) / 2;
            const x = centerX - rect.left;
            const y = centerY - rect.top;
            const scaleDiff = newScale - zoomScale;

            // 부드러운 확대/축소를 위한 위치 조정
            const newX =
              position.x - ((x - rect.width / 2) * scaleDiff) / zoomScale;
            const newY =
              position.y - ((y - rect.height / 2) * scaleDiff) / zoomScale;

            setZoomScale(newScale);
            setPosition({ x: newX, y: newY });
          }
        }
        lastTouchDistance.current = distance;
      } else if (e.touches.length === 1 && isDragging) {
        const newX = e.touches[0].clientX - dragStart.x;
        const newY = e.touches[0].clientY - dragStart.y;
        setPosition({ x: newX, y: newY });
      }
    },
    [isDragging, zoomScale, position, dragStart]
  );

  // 터치 종료
  const handleTouchEnd = useCallback(() => {
    setIsDragging(false);
    initialTouchDistance.current = null;
    lastTouchDistance.current = null;
    setUseTransition(true);
  }, []);

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent) => {
      if (zoomScale === 1) {
        const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setZoomScale(2);
        setPosition({
          x: -(x - rect.width / 2),
          y: -(y - rect.height / 2),
        });
      } else {
        setZoomScale(1);
        setPosition({ x: 0, y: 0 });
      }
    },
    [zoomScale]
  );

  // 마우스 이벤트
  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsDragging(true);
      setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    },
    [position]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) return;
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      setPosition({ x: newX, y: newY });
    },
    [isDragging, dragStart]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (mainSwiperRef.current) {
      mainSwiperRef.current.swiper.slideTo(selectedIndex);
    }
    if (previewSwiperRef.current) {
      previewSwiperRef.current.swiper.slideTo(selectedIndex);
    }
  }, [selectedIndex]);

  return (
    <>
      <Wrapper>
        <Header>
          <div className="title">사진 상세보기</div>
          {canEdit && (
            <ModeIndicator>
              <div
                className={`item ${mode === "normal" ? "selected" : ""}`}
                onClick={() => setMode("normal")}
              >
                일반 모드
              </div>
              <div
                className={`item ${mode === "edit" ? "selected" : ""}`}
                onClick={() => setMode("edit")}
              >
                편집 모드
              </div>
            </ModeIndicator>
          )}
          <div className="close" onClick={onClose}>
            {orientation === "landscape" ? (
              <img src="/image-web/Icon/Close_gray.svg" alt="close" />
            ) : (
              <img src="/image-web/Icon/Back_white.svg" alt="close" />
            )}
          </div>
          <div className="indicator">
            <span>{selectedIndex + 1}</span>
            <span>/</span>
            <span>{images.length}</span>
            {isMobile && canEdit && (
              <div className="button toggle-mode" onClick={handleClickEdit}>
                편집
              </div>
            )}
          </div>
        </Header>
        <ImageView>
          <Swiper
            ref={mainSwiperRef}
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={0}
            onSlideChange={handleSlide}
          >
            {images.map((url, index) => (
              <SwiperSlide key={index}>
                <ContentWrapper>
                  <img
                    className="content"
                    src={url}
                    alt="이미지"
                    onClick={handleZoomButtonClick}
                  />
                  {/* 각 이미지에 확대 버튼 추가 */}
                  <ZoomButton>
                    <ZoomIcon />
                  </ZoomButton>
                </ContentWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowButton
            direction="left"
            onClick={() => {
              setSelectedIndex(
                (selectedIndex - 1 + images.length) % images.length
              );
            }}
          >
            <img src="/image-web/Icon/Arrow-right.svg" alt="이전" />
          </ArrowButton>
          <ArrowButton
            direction="right"
            onClick={() => {
              setSelectedIndex((selectedIndex + 1) % images.length);
            }}
          >
            <img src="/image-web/Icon/Arrow-right.svg" alt="다음" />
          </ArrowButton>
          <Indicator>
            <span className="white">{selectedIndex + 1}</span>
            <span>/</span>
            <span>{images.length}</span>
          </Indicator>
        </ImageView>
        <PreviewWrapper>
          <Swiper
            ref={previewSwiperRef}
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={orientation === "landscape" ? 12 : 4}
          >
            {images.map((url, index) => (
              <SwiperSlide key={index}>
                <div
                  className="thumbnail"
                  data-selected={index === selectedIndex}
                  onClick={() => handleClickPreview(index)}
                >
                  <img src={url} alt={`썸네일 ${index + 1}`} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </PreviewWrapper>
      </Wrapper>
      <Dim />

      {/* 확대/축소 모달 */}
      {zoomModalOpen && (
        <ModalOverlay onClick={handleZoomModalClose}>
          <CloseButton onClick={handleZoomModalClose}>
            <CloseIcon />
          </CloseButton>

          <ImageContainer
            ref={containerRef}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onDoubleClick={handleDoubleClick}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <StyledImage
              ref={imageRef}
              src={images[selectedIndex]}
              alt="확대된 이미지"
              draggable={false}
              onLoad={handleImageLoad}
              $x={position.x}
              $y={position.y}
              $scale={zoomScale}
              $isLoading={isLoading}
              $transition={useTransition}
            />
          </ImageContainer>

          <ZoomInfo>{Math.round(zoomScale * 100)}%</ZoomInfo>
        </ModalOverlay>
      )}
    </>
  );
}

export default EnhancedImageViewer;
