import useQuickButtons from "../../../../hooks/useQuickButtons";
import { Cafe } from "../../../../api/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { navigationTargetState } from "../../../../recoil/store";
import { getKoreanNumber, getDistanceKm } from "../../../../utils/common";
import { getGameTypeString } from "../../../../utils/constants";
import { useEffect, useState } from "react";
import { geoCoordState } from "../../../../recoil/geo";
import { Backdrop, PubMoreWrapper } from "../style";

interface PubMoreProps {
  cafe: Cafe;
  setShowMoreOption: (value: boolean) => void;
  setSelectedStore: (value: Cafe | null) => void;
  showMoreOption: boolean;
}

const FranchiseMore = ({
  cafe,
  setShowMoreOption,
  setSelectedStore,
  showMoreOption,
}: PubMoreProps) => {
  const { openPhoneCall, toggleLike, shareLink } = useQuickButtons();
  const setNavigationTarget = useSetRecoilState(navigationTargetState);
  const { latitude, longitude } = useRecoilValue(geoCoordState);
  const [like, setLike] = useState(false);
  useEffect(() => {
    setLike(cafe.like);
  }, []);
  return (
    <>
      {showMoreOption && (
        <Backdrop
          onClick={() => {
            setShowMoreOption(false);
            setSelectedStore(null);
          }}
        />
      )}
      <PubMoreWrapper>
        <div className="info">
          <div className="tag-row">
            <div className="tag-box">
              <span className="tag">{getGameTypeString(cafe.gameTypes)}</span>
              {cafe.buyIn && (
                <span className="tag">
                  바이인 : {getKoreanNumber(cafe.buyIn)} ~{" "}
                  {cafe.buyInMax ? getKoreanNumber(cafe.buyInMax) : ""}
                </span>
              )}
            </div>
            <img
              src="/image-web/Holdem%20Now/Icon/Close.svg"
              onClick={() => {
                setShowMoreOption(false);
                setSelectedStore(null);
              }}
            />
          </div>
          <div className="title-row">
            <div className="name">{cafe.cafeName}</div>
          </div>
          <div className="address">
            {cafe.newAddress} {cafe.detailAddress}
          </div>

          <div className="info-row">
            <div className="item">
              <img className="icon" src="/image-web/Icon/Heart/small.svg" />
              <span className="text">{cafe.likeCount}</span>
            </div>
            <div className="item">
              <img className="icon" src="/image-web/Icon/Map%20pin/small.svg" />
              <span className="text">
                {getDistanceKm(latitude, longitude, cafe.lat, cafe.lon)} km
              </span>
            </div>
          </div>
        </div>
        <div className="menu-wrapper">
          <div
            className="item"
            onClick={() => {
              setShowMoreOption(false);
              setSelectedStore(null);
              openPhoneCall(
                cafe.cafeName,
                cafe.vcn ? cafe.vcn : cafe.phoneNumber
              );
            }}
          >
            <img src="/image-web/search/Icon/phone.svg" />
            <span>전화</span>
          </div>
          <div
            className="item"
            onClick={async () => {
              await toggleLike(cafe?.id!, "CAFE");
              setLike(!like);
            }}
          >
            <img
              src={`/image-web/search/Icon/${like ? "like_on" : "like"}.svg`}
            />
            <span>즐겨찾기</span>
          </div>
          <div
            className="item"
            onClick={() => {
              setShowMoreOption(false);
              setSelectedStore(null);
              setNavigationTarget(cafe);
            }}
          >
            <img src="/image-web/store/Navigation.svg" />
            <span>길안내</span>
          </div>
          <div
            className="item"
            onClick={() => {
              shareLink();
            }}
          >
            <img src="/image-web/store/Share.svg" />
            <span>공유</span>
          </div>
        </div>
      </PubMoreWrapper>
    </>
  );
};

export default FranchiseMore;
