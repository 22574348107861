import { useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "../StoreContext";
import { Swiper, SwiperSlide } from "swiper/react";
import OptimizedImage from "../../../../components/aws/OptimizedImage";
import EnhancedImageViewer from "../../../../components/EnhancedImageView";
import {
  EventBoxWrapper,
  EventImageWrapper,
  PubEventBox,
} from "./styles/PubEvent";

const PubEvent = () => {
  const { data } = useContext(StoreContext);
  const eventImageRef = useRef<HTMLDivElement>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const [eventImageAspectRatio, setEventImageAspectRatio] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    const calculateEventImageRatio = () => {
      if (eventImageRef.current) {
        const width = eventImageRef.current.clientWidth;
        const height = eventImageRef.current.clientHeight;

        // 높이가 0이 아닌 경우에만 비율 계산
        if (height > 0) {
          setEventImageAspectRatio(width / height);
        }
      }
    };

    // 초기 계산
    calculateEventImageRatio();

    // 리사이즈 이벤트에 대응
    const resizeObserver = new ResizeObserver(calculateEventImageRatio);
    if (eventImageRef.current) {
      resizeObserver.observe(eventImageRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <PubEventBox>
      <EventBoxWrapper>
        <div className="title">매장 이벤트</div>
        {data.events.length > 0 && (
          <Swiper
            slidesPerView={1.1}
            centeredSlides={true}
            spaceBetween={12}
            className="swiper"
          >
            {data.events.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <EventImageWrapper
                    ref={i === 0 ? eventImageRef : null}
                    onClick={() => setSelectedImageIndex(i)}
                  >
                    <OptimizedImage
                      alt="event"
                      src={item.imageUrl}
                      aspectRatio={eventImageAspectRatio}
                    ></OptimizedImage>
                  </EventImageWrapper>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </EventBoxWrapper>
      {selectedImageIndex !== -1 && (
        <EnhancedImageViewer
          images={data?.events.map((image) => image.imageUrl) || []}
          defaultIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(-1)}
        />
      )}
    </PubEventBox>
  );
};
export default PubEvent;
