import styled from "styled-components";
import { InfoBoxWrapper } from "../../../../../components/common/InfoBoxWrapper";
import { BoxWrapper } from "./DetailInfo";

export const PubEventBox = styled(InfoBoxWrapper)`
  padding-bottom: 100px;
  padding-left: 0px;
  padding-right: 0px;
`;

export const EventBoxWrapper = styled(BoxWrapper)`
  padding-left: 0px;
  padding-right: 0px;
  > .title {
    padding-left: 16px;
  }
  .swiper {
    width: 100%;
    height: 368px;
  }
`;

export const EventImageWrapper = styled.div`
  height: 368px;
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;
