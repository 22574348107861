import styled from "styled-components";

export const CertWrapper = styled.div<{isApp: boolean}>`
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  margin-top: 48px;
  padding: 20px 24px;
  padding-top: ${(p) => (p.isApp ? "64px" : "20px")};
`;
export const CertTitle = styled.div`
  color: ${(p) => p.theme.color.black500};
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
`;

export const CertInputWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  input {
    padding: 10px 0;
    width: 100%;
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: ${(p) => p.theme.color.black200};
    outline: none;
    text-align: left;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 0.3s;

    &:focus {
      border-bottom-color: ${(p) => p.theme.color.purple300};
    }

    &::placeholder {
      color: ${(p) => p.theme.color.black200};
    }
  }
`;

export const CertBottomButton = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background: var(--Purple-300, #6436e7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 30px;
`;

export const CertBottomInfo = styled.div`
  color: var(--Black-300, #808080);
  text-align: center;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 13px;
`;
