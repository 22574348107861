// OptimizedImage.tsx
import React, { useEffect, useState, useRef, useCallback } from "react";

interface OptimizedImageProps {
  src: string;
  alt: string;
  className?: string;
  aspectRatio?: number; // 예: 1.5 = 가로:세로 비율이 3:2
  quality?: number;
  maxWidth?: number; // 새로 추가: 최대 너비를 직접 지정할 수 있는 옵션
  size?: "small" | "medium" | "large" | "thumbnail" | "icon"; // 이미지 용도에 따른 사이즈 프리셋
  autoWidth?: boolean; // 자동으로 컨테이너 크기에 맞출지 여부
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({
  src,
  alt,
  className,
  aspectRatio,
  quality = 85,
  maxWidth,
  size,
  autoWidth = true, // 기본값은 true로 설정
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [containerWidth, setContainerWidth] = useState(0);
  const [imgError, setImgError] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  // 이미지 사이즈 프리셋 정의
  const sizePresets = {
    icon: { maxWidth: 32, quality: 80 },
    thumbnail: { maxWidth: 150, quality: 80 },
    small: { maxWidth: 300, quality: 85 },
    medium: { maxWidth: 500, quality: 85 },
    large: { maxWidth: 800, quality: 90 },
  };

  // 부모 요소의 너비 측정
  const updateContainerWidth = useCallback(() => {
    if (imgRef.current && autoWidth) {
      const parentElement = imgRef.current.parentElement;
      if (parentElement) {
        setContainerWidth(parentElement.clientWidth);
      }
    }
  }, [autoWidth]);

  // 화면 크기 변경 감지 및 컨테이너 크기 측정
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      updateContainerWidth();
    };

    // 초기 로드 시 컨테이너 너비 측정
    updateContainerWidth();

    window.addEventListener("resize", handleResize);

    // ResizeObserver 설정 (부모 컨테이너 크기 변경 감지)
    if (autoWidth && imgRef.current) {
      const parentElement = imgRef.current.parentElement;
      if (parentElement) {
        const resizeObserver = new ResizeObserver(() => {
          updateContainerWidth();
        });

        resizeObserver.observe(parentElement);

        return () => {
          window.removeEventListener("resize", handleResize);
          resizeObserver.unobserve(parentElement);
        };
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [autoWidth, updateContainerWidth]);

  // 이미지 사이즈 및 품질 설정
  const selectedPreset = size ? sizePresets[size] : null;
  const effectiveMaxWidth =
    maxWidth ||
    selectedPreset?.maxWidth ||
    (autoWidth && containerWidth > 0 ? containerWidth : 800);
  const effectiveQuality = selectedPreset?.quality || quality;

  // 로컬 이미지나 SVG는 변환하지 않음
  if (src.startsWith("/image-web/") || src.endsWith(".svg") || imgError) {
    return (
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        className={className}
        loading="lazy"
        style={{ width: "100%", height: aspectRatio ? "auto" : "100%" }}
      />
    );
  }

  // CloudFront 도메인 (Dynamic Image Transformation 배포)
  const cloudFrontDomain = "d9t7ym3dkwbwo.cloudfront.net";

  // 이미지 소스 분석
  let bucket = "runnerrunner-dev";
  let key = "";
  try {
    const url = new URL(src);

    // 버킷 하위 도메인 형식 (bucket-name.s3.{region}.amazonaws.com/...) - 이 조건을 먼저 확인
    if (
      url.hostname.includes(".s3.") &&
      url.hostname.endsWith(".amazonaws.com")
    ) {
      const hostnameParts = url.hostname.split(".s3.");
      if (hostnameParts.length > 0) {
        bucket = hostnameParts[0];
        key = url.pathname.substring(1); // 첫 번째 슬래시 제거
      }
    }
    // S3 리전 엔드포인트 URL 패턴 (s3.{region}.amazonaws.com/bucket-name/...)
    else if (
      url.hostname.startsWith("s3.") &&
      url.hostname.includes(".amazonaws.com")
    ) {
      const pathParts = url.pathname.substring(1).split("/");
      if (pathParts.length > 0) {
        bucket = pathParts[0]; // 첫 번째 경로 부분이 버킷 이름
        key = pathParts.slice(1).join("/"); // 나머지가 키
      }
    }
    // CloudFront 도메인 처리
    else if (url.hostname === "d10avy7otljqld.cloudfront.net") {
      bucket = "runnerrunner-dev";
      key = url.pathname.substring(1);
    } else if (url.hostname === "dfesoodpx4jgd.cloudfront.net") {
      bucket = "runnerrunner-prd";
      key = url.pathname.substring(1);
    } else {
      // 알 수 없는 소스의 경우 원본 반환
      return <img src={src} alt={alt} className={className} loading="lazy" />;
    }

    // 버킷이나 키가 비어있으면 원본 반환
    if (!bucket || !key) {
      return <img src={src} alt={alt} className={className} loading="lazy" />;
    }
  } catch (e) {
    // URL 파싱 실패 시 원본 반환
    return (
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        className={className}
        loading="lazy"
        style={{ width: "100%", height: aspectRatio ? "auto" : "100%" }}
      />
    );
  }

  // 키 정규화 처리
  try {
    // 이미 인코딩된 URL을 디코딩하여 원래 형태로 복원
    key = decodeURIComponent(key);
  } catch (e) {
    // 디코딩 실패 시 원본 유지
    console.error("URL 디코딩 실패:", key);
  }

  // 현재 화면 크기와 요소 크기에 따른 최적 이미지 너비 계산
  const calculateWidth = () => {
    // 요소가 화면의 특정 비율을 차지하는지 확인
    let elementWidth = effectiveMaxWidth;

    // 화면 너비와 maxWidth 중 작은 값 사용
    const minWidth = Math.min(screenWidth, elementWidth);

    // 기기 픽셀 비율(DPR) 고려
    const pixelRatio = 1; //window.devicePixelRatio || 1;
    const dpr = minWidth * pixelRatio;

    // 최종 이미지 너비 계산 (DPR 고려, 너무 크지 않게 제한)
    const result = Math.min(Math.round(dpr), 2048);
    return result;
  };

  const imageWidth = calculateWidth();
  const imageHeight = Math.round(
    imageWidth / (aspectRatio ? aspectRatio : 1.67)
  );

  const imageRequest = {
    bucket,
    key,
    edits: {
      resize: {
        width: imageWidth,
        height: imageHeight,
        fit: "cover",
      },
      webp: { quality: effectiveQuality },
    },
  };

  // Base64로 인코딩
  const encodedRequest = btoa(
    encodeURIComponent(JSON.stringify(imageRequest)).replace(
      /%([0-9A-F]{2})/g,
      (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      }
    )
  );
  const optimizedUrl = `https://${cloudFrontDomain}/${encodedRequest}`;

  return (
    <img
      ref={imgRef}
      src={optimizedUrl}
      alt={alt}
      className={className}
      loading="lazy"
      onError={() => {
        setImgError(true);
      }}
      style={{ width: "100%", height: aspectRatio ? "auto" : "100%" }}
    />
  );
};

export default React.memo(OptimizedImage);
