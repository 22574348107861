import { enqueueSnackbar } from "notistack";
import {
  CertBottomButton,
  CertBottomInfo,
  CertInputWrapper,
  CertTitle,
  CertWrapper,
} from "./styles/cert";
import { isApp } from "../../../hooks/useNativeApp";

interface StepProps {
  certPhone: string;
  setCertPhone: (phone: string) => void;
  onNext: () => void;
}

const StepCertPhone = ({ certPhone, setCertPhone, onNext }: StepProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 숫자만 입력 가능하고 최대 11자리
    if (/^\d*$/.test(value) && value.length <= 11) {
      setCertPhone(value);
    }
  };

  const handleNext = () => {
    if (certPhone.length === 0) {
      enqueueSnackbar("전화번호를 입력해 주세요.", { variant: "error" });
      return;
    }

    if (certPhone.length !== 11) {
      enqueueSnackbar("전화번호를 11자리로 입력해 주세요.", {
        variant: "error",
      });
      return;
    }

    if (!certPhone.startsWith("010")) {
      enqueueSnackbar("010으로 시작하는 번호만 입력 가능합니다.", {
        variant: "error",
      });
      return;
    }

    onNext();
  };

  return (
    <CertWrapper isApp={false}>
      <CertTitle>전화번호를 입력해 주세요.</CertTitle>
      <CertInputWrapper>
        <input
          type="text"
          value={certPhone}
          onChange={handleInputChange}
          placeholder="전화번호를 입력해주세요.(예: 01012345678)"
        />
      </CertInputWrapper>
      <CertBottomButton onClick={handleNext}>다음</CertBottomButton>
      <CertBottomInfo>
        PASS 인증을 하지 않은 계정의 경우, 매장 이용 시 본인 확인
        <br /> (신분증, 여권 등) 정보와 일치하지 않으면 매장 이용이
        불가능합니다.
      </CertBottomInfo>
    </CertWrapper>
  );
};

export default StepCertPhone;
