import styled from "styled-components";

export const DialogWrapper = styled.div`
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

export const FormContainer = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 8px;
  padding: 30px 20px;
  width: calc(100% - 80px);
  left: 40px;
  max-width: 400px;
`;

export const Title = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
`;

export const ContentWrapper= styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;

  > .seed {
   font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
  }

  > .content {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 700;
  }

  > .waring {
    font-family: Pretendard;
    font-size: 11px;
    font-weight: 600;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;

  > .button {
    flex: 1;
    color: #6436E7;
    text-align: center;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    height: 35px;
    line-height: 35px;

    &.purple {
      color: #fff;
      background: #6436E7;
      border-radius: 5px;
    }
  }
`