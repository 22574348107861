import { useEffect, useState } from "react";
import { AlarmItemWrapper, AlarmListWrapper } from "../style";
import {
  getNoticesListAndRead,
  NotificationResponse,
} from "../../../../api/user-notification";

const AlarmList = () => {
  const [list, setList] = useState<NotificationResponse[]>([]);

  const getDate = async () => {
    try {
      const res = await getNoticesListAndRead();
      setList(res.list);
    } catch {
      setList([]);
    }
  };

  useEffect(() => {
    getDate();
  }, []);

  const formatTime = (time: number) => {
    if (time >= 86400) {
      const newTime = Math.floor(time / 86400);
      return `${newTime}일`;
    } else if (time >= 3600) {
      const newTime = Math.floor(time / 3600);
      return `${newTime}시간`;
    } else if (time >= 60) {
      const newTime = Math.floor(time / 60);
      return `${newTime}분`;
    } else {
      return "방금";
    }
  };

  return (
    <AlarmListWrapper>
      {list.length === 0 ? (
        <div className="no-data">최근 알림이 없습니다.</div>
      ) : (
        list.map((item, index) => (
          <AlarmItemWrapper key={index}>
            <div className="icon">
              <img src={`/image-web/alarm/${item.type}.svg`} alt="icon" />
            </div>
            <div className="item-wrapper">
              <div className="top">
                <div className="title">{item.title}</div>
                <div className="time">{formatTime(item.elapsedTime)}전</div>
              </div>
              <div className="text">{item.content}</div>
            </div>
          </AlarmItemWrapper>
        ))
      )}
    </AlarmListWrapper>
  );
};

export default AlarmList;
