import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import {
  CertBottomButton,
  CertBottomInfo,
  CertInputWrapper,
  CertTitle,
  CertWrapper,
} from "./styles/cert";
import { isApp } from "../../../hooks/useNativeApp";

interface StepProps {
  certBirthYear: string;
  setCertBirthYear: (year: string) => void;
  certBirthMonth: string;
  setCertBirthMonth: (month: string) => void;
  certBirthDate: string;
  setCertBirthDate: (date: string) => void;
  onNext: () => void;
}

const StepCertBirth = ({
  certBirthYear,
  setCertBirthYear,
  certBirthMonth,
  setCertBirthMonth,
  certBirthDate,
  setCertBirthDate,
  onNext,
}: StepProps) => {
  const [birth, setBirth] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 숫자만 입력 가능
    if (/^\d*$/.test(value) && value.length <= 8) {
      setBirth(value);
    }
  };

  const isValidDate = (year: number, month: number, day: number) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  const handleNext = () => {
    if (birth.length === 0) {
      enqueueSnackbar("생년월일을 입력해 주세요.", { variant: "error" });
      return;
    }

    if (birth.length !== 8) {
      enqueueSnackbar("생년월일을 8자리로 입력해 주세요.", {
        variant: "error",
      });
      return;
    }

    const year = parseInt(birth.substring(0, 4));
    const month = parseInt(birth.substring(4, 6));
    const day = parseInt(birth.substring(6, 8));

    const currentYear = new Date().getFullYear();

    if (year < 1900 || year > currentYear) {
      enqueueSnackbar("올바른 연도를 입력해 주세요.", { variant: "error" });
      return;
    }

    if (!isValidDate(year, month, day)) {
      enqueueSnackbar("올바른 생년월일을 입력해 주세요.", { variant: "error" });
      return;
    }

    if (year >= currentYear - 18) {
      enqueueSnackbar("성인만 이용가능합니다.", { variant: "error" });
      return;
    }

    setCertBirthYear(birth.substring(0, 4));
    setCertBirthMonth(birth.substring(4, 6));
    setCertBirthDate(birth.substring(6, 8));

    onNext();
  };

  return (
    <CertWrapper isApp={false}>
      <CertTitle>생년월일을 입력해 주세요.</CertTitle>
      <CertInputWrapper>
        <input
          type="text"
          value={birth}
          onChange={handleInputChange}
          placeholder="생년월일을 입력해주세요.(예: 19900101)"
        />
      </CertInputWrapper>
      <CertBottomButton onClick={handleNext}>다음</CertBottomButton>
      <CertBottomInfo>
        PASS 인증을 하지 않은 계정의 경우, 매장 이용 시 본인 확인
        <br /> (신분증, 여권 등) 정보와 일치하지 않으면 매장 이용이
        불가능합니다.
      </CertBottomInfo>
    </CertWrapper>
  );
};

export default StepCertBirth;
