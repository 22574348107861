import { enqueueSnackbar } from "notistack";
import { GameCouponDialogProps } from "../Hook/gameCouponDialogHook";
import { useGameContext } from "../Hook/GameContext";
import { GameCouponPopupWrapper } from "../Style/GameCouponStyles";
import { useGameCoupon } from "../../../../api/game";

const GameCouponDialog = (props: { data: GameCouponDialogProps }) => {
  const data = props.data;
  const {
    closeCouponDialog,
    code,
    setCode,
    openDialog,
    openCouponDialog,
    getUserTicket,
  } = useGameContext();
  const handleGameCoupon = async () => {
    if (code.trim() === "") {
      enqueueSnackbar("쿠폰 번호를 입력해주세요");
    }
    try {
      const res = await useGameCoupon(code);
      const result = res.result;
      console.log(result);
      setCode("");
      if (result === -5) {
        openDialog({
          text: "없는 쿠폰 번호입니다.\n다시 확인해보세요.",
          buttonText: "확인",
          onConfirm: () => {
            openCouponDialog({});
          },
        });
      } else if (result === -3) {
        openDialog({
          text: "로그인이 만료되었습니다.\n다시 로그인 해주세요.",
          buttonText: "확인",
          onConfirm: () => {
            openCouponDialog({});
          },
        });
      } else if (result === -6) {
        openDialog({
          text: "사용된 쿠폰 번호입니다.\n다시 확인해보세요.",
          buttonText: "확인",
          onConfirm: () => {
            openCouponDialog({});
          },
        });
      } else if (result === -7) {
        openDialog({
          text: "만료된 쿠폰 번호입니다.\n다시 확인해보세요.",
          buttonText: "확인",
          onConfirm: () => {
            openCouponDialog({});
          },
        });
      } else if (result === 1) {
        openDialog({
          text: "쿠폰이\n적용 되었습니다.",
          buttonText: "확인",
        });
        getUserTicket();
      }

      closeCouponDialog();
    } catch (e: any) {
      console.log(e);
    }
  };
  return (
    <GameCouponPopupWrapper>
      <div className="content">
        <div className="wrapper">
          <div className="cancel">
            <img
              alt="취소"
              src="/image-web/game/cancel.svg"
              onClick={() => {
                closeCouponDialog();
              }}
            />
          </div>
          <div className="title">{"게임 쿠폰코드를\n입력 해주세요."}</div>
          <div className="input-box">
            <input
              maxLength={20}
              placeholder="RUNNERCOUPON"
              type="text"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <div className="button" onClick={handleGameCoupon}>
              확인
            </div>
          </div>
        </div>
      </div>
    </GameCouponPopupWrapper>
  );
};

export default GameCouponDialog;
